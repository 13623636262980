@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@media (max-width: $screen-xs-max){
    .col-xs-offset-15 {
        margin-left: 12.5%;
    }
}

@media (min-width: $screen-sm-min){   
    .col-sm-offset-45 {
        margin-left: 37.5%;
    }
}

/*******************/
/*      COLORS     */

$varadident_orange: #d3a836;
$varadident_light_orange:#ecddb2;
$varadident_light_gray:#efecec;
$varadident_dark_gray: #9c9c9c;

$box_shadow_bottom_only: 0 1px 4px 0 rgba(0, 0, 0, 0.2);

html {
    position: relative;
    min-height: 100%;
}

html.hidden {
    display: block !important;
}

body {
    font-family: 'open_sanslight';
    background-color: #f8f6f7;
    margin-bottom: 230px;
    @media (max-width: $screen-xs-max) {
        margin-bottom: 500px;
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-bottom: 300px;
    }
}

/*******************/
/* GLOBAL ELEMENTS */

a {
    color: $varadident_orange;
    &:hover {
        color: $varadident_dark_gray;
        text-decoration: underline;
        text-decoration-color: $varadident_dark_gray;
    }
    figcaption {
            color: $varadident_orange;
        &:hover {
            color: $varadident_dark_gray;
            text-decoration: underline;
            text-decoration-color: $varadident_dark_gray;
        }
    }
}

p, h3  {
    span {
        font-family: 'open_sanslight_italic';
        font-size: 1.2em;
    }
}

section.row {
    margin-top: 47px;
    &#intro {
        margin-top: 0;
    }
}

.no-gutter > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.zero_padding {
    padding: 0;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
}

.slick-next {
    right: 5px;
    color: #000;
}

.lightbox {
    padding-bottom: 20px;
    img {
        margin: 20px 0 0 0;
    }
}

.anchor {
    position: relative;
}
.anchor:before {
    display: block;
    content: " ";
    height: 110px;
    margin-top: -110px;
    visibility: hidden;
    @media (max-width: $screen-xs-max){   
        height: 130px;
        margin-top: -130px;
    }
}

a.btn, button {
    position: relative;
    margin: 0 auto;
    border: none;
    border-radius: 0;
    background-color: $varadident_light_gray;
    color: $varadident_orange;
    font-family: 'open_sansregular';
    font-size: 1.5em;
    text-transform: uppercase;
    padding: 10px 60px;
    &:hover {
        background-color: $varadident_dark_gray;
        color: #fff;
    }
}

form {
    input {
        font-family: 'open_sansregular';
    }
    input[type=submit], input[type=file], .panel-default > .panel-heading, a>.panel-heading {
        border: none;
        background-color: $varadident_light_gray;
        color: $varadident_orange;
        font-size: 1.3em;
        text-transform: uppercase;
        padding: 10px 20px;
        cursor: pointer;
        font-family: 'open_sansregular';
        &:hover, &:focus {
            background-color: #e2e2e2;
            text-decoration: none;
        }
    }
    input[type=file] {
        padding: 10px 20px 40px;
    }
    textarea, select {
        border: none;
        background-color: #fff;
        padding: 10px;
    }
    input[type=text], input[type=email] {
        border: none;
        background-color: #fff;
        padding: 10px;
        font-size: 1.3em;
    }
    label {
        font-size: 1.5em;
        font-family: 'open_sansregular';
        font-weight: normal;
    }
    .form-group {
        .form-control {
            display: block;
            font-size: 1.3em;
            line-height: 1.5;
            border-radius: 0;
            box-shadow: none;
            transition: none;
        }
    }
    @media (max-width: $screen-md-max){
        .address {
            width: 50%;
        }
        .utvonal_submit {
            width: 49%;
        }
    }
}

main {
    margin-top: 43px;
}

.intro_block {
    article {
        height: 387px;
        margin-top: 20px;
        padding: 0;
        img {
            float: left;
            height: auto;
            margin-right: 20px;
        }
        header {
            position: absolute;
            top: 0;
            right: 0;
            padding: 9px 40px 15px 20px;
            height: 68px;
            margin-top: -20px;
            box-shadow: $box_shadow_bottom_only;
            background-color: $varadident_orange;
            h1 {
                font-family: 'notera';
                font-size: 3.5em;
                text-transform: none;
                line-height: 47px;
                color: #fff;
                margin: 0;
            }
        }
        ul {
            margin: 100px 0 31px;
            padding-left: 0;
            float: left;
            li {
                list-style: none;
                font-family: 'open_sanslight_italic';
                font-size: 1.8em;
            }
        }
        @media (max-width: $screen-md-max){
            height: auto;
            img {
                margin-right: 0;
            }
            ul {
                float: left;
                margin: 50px 0 31px 20px;
                padding: 0;
            }
            header {
                position: relative;
                float: left;
                height: auto;
                width: 100%;
            }
        }
        @media (max-width: $screen-xs-max){
            img {
                width: 100%;
            }
            ul {
                width: 100%;
                padding: 0px;
            }
            a.btn {
                position: relative;
                float: right;
                padding: 20px 0;
            }
        }
    }
}

header.main {
    background-color: #fff;
    border-bottom: 2px solid $varadident_orange;
    height: auto;
    padding: 5px 5px 5px 20px;
    margin-bottom: 40px;
    h2 {
        color: #000;
        font-family: 'open_sanslight';
        font-size: 1.9em;
        text-transform: uppercase;
    }
}
article {
    position: relative;
    float: left;
    background-color: #fff;
    width: 100%;
    margin-bottom: 30px;
    img {
        float: left;
        height: 229px;
    }
    &.main {
        padding-left: 25px !important;
        padding-right: 25px !important;
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }
    header.sub {
        float: left;
        border: none;
        padding-bottom: 10px;
        margin-top: 10px;
        margin-bottom: 0;
        width: 100%;
        h3 {
            font-family: 'open_sanslight';
            font-size: 1.9em;
            text-transform: none;
            margin-top: 0;
            @media (max-width: $screen-xs-max){
                font-weight: bold;
            }
        }
    }
    p {
        float: left;
        font-size: 1.5em;
        text-align: justify;
    }
    a.btn {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    &.with_btn {
        padding-left: 25px !important;
        padding-right: 25px !important;
        padding-top: 25px !important;
        padding-bottom: 70px !important;
    }
    @media (max-width: $screen-xs-max){
        img {
            width: 100%;
            height: auto;
            margin: 0;
        }
        header {
            margin-bottom: 0px;
        }
        header.sub {
            h3 {
                font-size: 1.6em;
            }
        }
        p {
            width: 100%;
        }
        &.with_btn, &.slide {
            a {
                width: 100%;
                padding: 20px 0;
            }
        }
    }
}


/****************/
/* RIGHT COLUMN */

.right_column {
    a {
        &:hover {
            text-decoration: none;
        }
        .block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: $varadident_light_gray;
            height: 180px;
            margin: 0 0 47px 47px;
            &.bejelentkezes {
                background: url('/img/naptar.jpg') center/100% no-repeat;
            }
            &.map {
                background: url('/img/terkep.jpg') center/100% no-repeat;
            }
            .title {
                text-transform: uppercase;
                font-family: 'open_sanssemibold';
                font-size: 1.7em;
                text-align: left;
                background-color: rgba(156, 156, 156, 0.72);
                color: #fff;
                padding: 13px;
                @media (max-width: 1135px) {
                    font-size: 1.5em;
                }
            }
            &:hover {
                cursor: pointer;
                .title {
                    background-color: rgba(255, 255, 255, 0.8);
                    color: $varadident_dark_gray;
                }
            }
        }
    }
}

/****************/
/* HEADER */

header{
    &#base {
        height: 110px;
        #header {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 110px;
            background-color: #fff;
            z-index: 999;
            -webkit-transition: height 0.3s;
            -moz-transition: height 0.3s;
            transition: height 0.3s;
            .header_title {
                position: absolute;
                top: 14px;
                width: 100px;
                height: 82px;
                -webkit-transition: all 0.3s;
                -moz-transition: all 0.3s;
                transition: all 0.3s;
                img {
                    width: 100%;
                }
            }
            #main_menu {
                position: absolute;
                top: 69px;
                right: 40px;
                z-index: 900;
                -webkit-transition: all 0.3s;
                -moz-transition: all 0.3s;
                transition: all 0.3s;
                &.navbar {
                    min-height: auto;
                    margin-bottom: 0;
                }
                ul {
                    li {
                        &.active {
                            background-color: $varadident_orange;
                            a {
                                color: #fff;
                                &:hover, &:focus, &:active, &.active {
                                    background-color: $varadident_orange;
                                    color: #fff;
                                }
                            }
                        }
                        a {
                            font-family: 'open_sanslight';
                            font-size: 1.5em;
                            color: #000;
                            text-transform: uppercase;
                            padding-top: 9px;
                            padding-bottom: 9px;
                            &:hover, &:focus, &:active, &.active {
                                background-color: $varadident_light_orange;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            #sitemap_menu {
                position: absolute;
                right: 4px;
                bottom: 12px;
                font-size: 2em;
                color: #000;
                width: 34px;
                cursor: pointer;
                .bars {
                    border-top: 3px solid $varadident_orange;
                    border-bottom: 3px solid $varadident_orange;
                    height: 20px;
                    width: 34px;
                    .middle_bar {
                        border-bottom: 3px solid $varadident_orange;
                        height: 9px;
                        width: 100%;
                    }
                }
            }
            #language_selector {
                position: absolute;
                right: 4px;
                top: 14px;
                transition: all 0.3s;
                .flag {
                    position: relative;
                    float: right;
                    width: 30px;
                    margin-left: 15px;
                    transition: all 0.3s;
                }
            }
            .header_line {
                border-bottom: 2px solid #000;
                position: absolute;
                bottom: 0;
                width: 100%;
                @media (max-width: $screen-xs-max){
                    border-bottom: 1px solid #000;
                }
            }
            .small_screen_btns {
                position: fixed;
                top: 84px;
                width: 100%;
                border-bottom: 2px solid #000;
                transition: all 0.3s;
                @media (max-width: $screen-xs-max){
                    border-bottom: 1px solid #000;
                }
                a.btn {
                    border: none;
                    background-color: #fff;
                    color: $varadident_orange;
                    font-family: 'open_sansregular';
                    font-size: 1.1em;
                    text-transform: uppercase;
                    padding: 10px 0;
                    &:hover {
                        background-color: $varadident_dark_gray;
                        color: #fff;
                    }
                }
                @media (min-width: 1075px) {
                    display: none;
                }
            }
        }
        @media (max-width: 1074px) {
        height: 84px;
            #header {
                height: 84px;
                padding: 0 15px;
                .header_title {
                    width: 69px;
                }
                #main_menu {
                    display: none;
                }
                #sitemap_menu {
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                    width: 50px;
                    .bars {
                        height: 30px;
                        width: 50px;
                        .middle_bar {
                            height: 13px;
                        }
                    }
                }
            }
        }
        &.short {
            height: 70px;
            #header {
                width: 100%;
                height: 70px;
                background-color: #fff;
                position: fixed;
                z-index: 999;
                .small_screen_btns {
                    top: 70px;
                }
                .header_title {
                    position: absolute;
                    top: 14px;
                    width: 80px;
                    height: 45px;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                }
                #main_menu {
                    position: absolute;
                    top: 29px;
                }
                #language_selector {
                    top: 3px;
                    .flag {
                        width: 30px;
                    }
                }
            }
        }
    }
}

/****************/
/* LANGUAGE */

#language {
    //background: url('/img/virag.jpg') center 69px/50% no-repeat #fff;
    .logo {
        margin: 1em auto 6.5em;
        position: relative;
    }
    .bottom_line {
        display: none;
        position: relative;
        width: 100%;
        border-bottom: 1px solid $varadident_dark_gray;
        margin-bottom: 6.5em;
    }
    .zaszlok {
        position: relative;
        float: left;
        .zaszlo {
            margin-bottom: 30px;
            figure {
                float: left;
                figcaption {
                    color: $varadident_orange;
                    font-family: 'open_sansregular';
                    font-size: 1.5em;
                    text-transform: uppercase;
                }
            }
            @media (max-width: $screen-xs-max) {
            }
        }
    }
}

/****************/
/* WELCOME */

#welcome {
    #specialitasunk {
        article {
            padding: 20px 15px;
        }
    }
    #szolgaltatasaink {
        article {
            &.with_btn {
                padding-bottom: 0;
                padding-top: 0 !important;
                padding-left: 0 !important;
                padding-bottom: 0px !important;
                img {
                    padding-right: 15px;
                }
                p {
                    width: 65%;
                    @media (max-width: $screen-md-min){
                        width: 72%;
                    }
                }
                @media (max-width: $screen-xs-max){
                    padding-bottom: 70px !important;
                    padding-right: 0 !important;
                    img {
                        padding-right: 0 !important;
                    }
                    header.sub, p {
                        margin-left: 15px;
                        padding-right: 15px;
                        width: 96%;
                    }
                }
                @media (min-width: $screen-sm-min){
                    header.sub {
                        width: 65%;
                    }
                }
            }
        }
    }
}

/****************/
/* SZOLGALTATAS-INFORMÁCIÓ */

#szolgaltatasok, #szolgaltatasaink, #szolgaltatas, #informacio, #informaciok {
    .intro_block {
        ul {
            @media (min-width: $screen-sm-min){
                margin: 61px 0 0;
            }
        }
        li {
            padding: 10px 0;
            a {
                font-family: 'open_sanslight';
                font-size: 1.1em;
                color: $varadident_orange;
                padding: 5px;
                &:hover {
                    text-decoration: none;
                    background-color: $varadident_light_gray;
                }
            }
        }
    }
}
#informaciok .intro_block li {
    padding: 7px 0;
}
#szolgaltatasok {
    article {
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: #fff;
        &:hover {
            background-color: $varadident_light_gray;
            text-decoration: underline;
        }
        &.main {
            &:hover {
                background-color: #fff;
            } 
        }
        header.sub {
            padding: 3% 0;
            h3 {
                font-size: 2.8em;
                text-align: center;
                color: $varadident_orange;
                text-transform: uppercase;
            }
        }
        img {
            height: auto;
        }
    }
}
#szolgaltatas, #informaciok {
    #ajanlott_informacio, #ajanlott_szolgaltatas {
        header.main {
            margin-bottom: 0;
            background-color: $varadident_dark_gray;
            border-bottom: 1px solid $varadident_orange;
            h2 {
                color: #fff;
            }
        }
    }
    .prev_next {
        a {
            .prev.btn, .next.btn {
                background-color: $varadident_light_gray;
                border-radius: 0;
                padding: 10px;
                p {
                    color: $varadident_orange;
                    text-transform: uppercase;
                    font-size: 1.6em;
                    margin: 0;
                }
                i {
                    font-size: 1.5em;
                    color: $varadident_orange;
                    margin-top: 6px;
                }
            }
            &:hover {
                .prev.btn, .next.btn {
                    background-color: $varadident_dark_gray;
                    p {
                        text-decoration: none;
                        color: #fff;
                    }
                    i {
                        text-decoration: none;
                        color: #fff;
                    }
                }
            }
        }
        @media (max-width: $screen-xs-max){
            .prev {
                margin-bottom: 20px;
            }
        }
    }
}

/****************/
/* BEJELENTKEZÉS */

#bejelentkezes {
    button {
        padding: 10px 0;
        width: 100%;
        min-width: 90px;
        margin-bottom: 20px;
    }
}

/****************/
/* ROLUNK */

#rolunk {
    #csapat {
        .szemely_wrapper {
            position: relative;
            float: left;
            width: 100%;
            .szemely {
                figure {
                    img {
                        width: 100%;
                    }
                    figcaption {
                        background-color: $varadident_orange;
                        color: #fff;
                        font-family: 'open_sanssemibold';
                        font-size: 1.8em;
                        text-align: center;
                    }
                }
                p {
                    text-align: center;
                    padding: 10px;
                    background-color: #fff;
                    font-size: 1.5em;
                }
            }
        }
    }
    #partnereink {
        img  {
            box-shadow: 0px 12px 20px -12px rgba(0,0,0,0.59)
        }
    }
}

/****************/
/* HEVIZ */

#heviz {
    .bordered_block {
        border-bottom: 2px solid $varadident_light_gray;
        margin-bottom: 20px;
    }
    .szalloda {
        position: relative;
        float: left;
        width: 100%;
        .sub  {
            padding-left: 15px;
            width: auto;
        }
    }
}


/****************/
/* KAPCSOLAT */

#kapcsolat {
    #intro {
        .intro_block {
            article {
                .vcard {
                    margin-top: 80px;
                    float: left;
                    width: 315px;
                    .block {
                        float: left;
                        margin-bottom: 25px;
                        i {
                            float: left;
                        }
                        &.email {
                            i {
                                font-size: 1.5em;
                                margin-left: 2px;
                            }
                        }
                        p {
                            padding: 0 0 0 15px;
                            margin: 0;
                            font-size: 1.3em;
                        }
                    }
                }
                @media (max-width: $screen-md-max){
                    img {
                        margin-right: 20px;
                    }
                    .vcard {
                        margin-top: 30px;
                        width: 305px;
                        .block {
                            margin-bottom: 40px;
                            i {
                                font-size: 1.6em;
                            }
                            &.email {
                                i {
                                    font-size: 1.4em;
                                }
                            }
                        }
                    }
                }
                @media (max-width: $screen-xs-max){
                   .vcard {
                        width: 100%;
                        .block {
                            width: 100%;
                            i {
                                float: none;
                                display: block;
                                text-align: center;
                                font-size: 2.6em;
                            }
                            &.email {
                                i {
                                    font-size: 2.1em;
                                }
                            }
                            p {
                                text-align: center;
                                font-size: 2em;
                            }
                        }
                    }
                }
            }
        }
    }
    #utvonaltervezo {
        #directions {
            float: left;
            width: 25%;
            margin-top: 20px;
            height: 400px;
            overflow-y: auto;
            display: none;
            @media (max-width: $screen-xs-max){
                width: 90%;
                margin: 0 5%;
            }
            ul {
                list-style-type: none;
                margin-left: -40px;
                li {
                    font-family: 'open_sansregular';
                    margin: 5px 0;
                    border-bottom: 2px solid #eee;
                }
            }
        }
        .google_map {
            position: relative;
            float: left;
            //width: 92%;
            width: 97%;
            padding-bottom: 27%; // This is the aspect ratio
            padding-top: 45px;
            //margin: 20px 15px 35px;
            margin: 20px 0px 35px;
            height: 0;
            overflow: hidden;
            @media (max-width: $screen-xs-max){
                //width: 92%;
            }
            .google_map iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}

/****************/
/* FOOTER */

footer {
    position: absolute;
    bottom: 0;
    background-color: $varadident_orange;
    color: #fff;
    width: 100%;
    height: 230px;
    font-size: 1.8em;
    padding: 20px;
    margin-top: 40px;
    @media (max-width: $screen-xs-max){
        height: 500px; 
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        height: 300px;
    }
    a {
        color: #fff;
        &:hover {
            text-decoration: none;
            color: $varadident_dark_gray;
        }
    }
    .vcard {
        position: relative;
        float: left;
        width: 100%;
        .pull-right {
            text-align: right;
        }
        @media (max-width: $screen-xs-max){
            text-align: center;
            .pull-right {
                text-align: center;
            }
        }
    }
    .copyright {
        font-size: 0.8em;
        position: relative;
        float: left;
        width: 100%;
        text-align: center;
    }
}

/*******************/
/* FULLSCREEN MENU */
/*******************/

.overlay, .overlay2 {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.98);
    overflow-x: auto;
    &.open {
        z-index: 10000;
    }
    /* Overlay closing cross */
    .overlay-close {
        width: 58px;
        height: 58px;
        position: absolute;
        right: 0px;
        top: 40px;
        overflow: hidden;
        border: none;
        background: url('/img/cross.png') no-repeat;
        text-indent: 200%;
        color: transparent;
        outline: none;
        z-index: 100;
        padding: 0;
        margin: 10px;
        @media (max-width: 1074px){
            right: 0px;
            left: 0px;
            top: 20px;
            margin: 0 auto;
        }
        @media (max-width: 768px){
            width: 43px;
            height: 28px;
            padding: 0;
            background: url('/img/cross.png') 100% no-repeat;
        }
    }
    /* Menu style */
    nav {
        text-align: center;
        position: relative;
        top: 26%;
        height: 60%;
        transform: translateY(-20%);
        @media (max-width: 768px){
            top: 20%;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        height: 100%;
        position: relative;
        li {
            display: block;
            height: 20%;
            height: calc(100% / 6);
            min-height: 45px;
            width: 100%;
            a {
                font-size: 37px;
                font-weight: 300;
                display: block;
                color: $varadident_orange;
                transition: color 0.2s;
                text-transform: uppercase;
                padding: 15px;
                &:hover, &:focus {
                    color: $varadident_dark_gray;
                    background-color: rgba(255, 255, 255, 0.98);
                    font-weight: 500;
                    text-decoration: none;
                }
                .caret {
                    width: 15px;
                    height: 15px;
                    margin-left: 10px;
                    vertical-align: middle;
                    border-top: 10px solid;
                    border-right: 8px solid transparent;
                    border-left: 8px solid transparent;
                }
            }
            @media (max-width: 768px){
                min-height: 41px;
                a {
                    font-size: 26px;
                    padding: 10px;
                }
            }
            ul.dropdown-menu {
                box-shadow:none;
                border: none;
                width: 100%;
                text-align: center;
                background-color: rgba(238, 238, 238, 0.5);
                li {
                    width: 100%;
                    a {
                        font-size: 30px;
                        padding: 7px;
                        &:hover, &:focus {
                            background-color: rgba(238, 238, 238, 0.5);
                        }
                        @media (max-width: 768px){
                            font-size: 20px;
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }
}

/* Effects */
.container-fluid {
    transition: transform 0.5s;
}

.container-fluid.overlay-open {
    transform: scale(0.8);
}

.overlay-contentscale {
    visibility: hidden;
    transform: translateY(100%);
    transition: transform 0.5s, visibility 0s 0.5s;
}

.overlay-contentscale.open {
    visibility: visible;
    transform: translateY(0%);
    transition: transform 0.5s;
}
@media screen and (max-height: 30.5em) {
    .overlay nav {
        height: 70%;
        font-size: 34px;
    }
    .overlay ul li {
        min-height: 34px;
    }
}

/* Grow */
.grow_center {
    transition: all 0.3s ease-in-out;
    transition-property: transform;

    &:hover,
    &:focus,
    &:active {
        transform: scale(1.25);
    }
}

.grow_up{
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
        transform: translate(0%,-10%) scaleX(1.15) scaleY(1.15);
    }
}

/* From: http://tobiasahlin.com/spinkit/    2.  */
#preloader {
    background-color: #ffffff;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9997;
}

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
  z-index: 9998;
}
.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $varadident_orange;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}